import * as yup from "yup";

export const emailSchema = yup.object({
	email: yup.string().email().required(),
});

export const loginSchema = yup.object({
	email: yup.string().email().required(),
	password: yup.string().required(),
});

export const userSchema = yup.object({
	fullName: yup.string().trim().label('user name').required("user name is required"),
	role: yup.string().trim().required("role is required"),
	email: yup.string().lowercase().trim().email('user email address must be valid').required("user email is required"),
	password: yup.string().required("password is required"),
});

export const categorySchema = yup.object({
	title: yup.string().trim().required("shop category is required"),
});

export const facilitySchema = yup.object({
	id: yup.string().uuid().nullable(),
	facilityName: yup.string().trim().label('facility name'),
	location: yup.string().trim(),
	registrationNumber: yup.string().trim().label('registration number'),
});

export const onboardingFacilitySchema = yup.object({
	facilityName: yup.string().trim().label('facility name').required(),
	type: yup.string().trim().required(),
	contact: yup.string().trim().max(15).min(10).required(),
	email: yup.string().trim().email().required(),
	location: yup.string().trim().required(),
});

export const onboardingStaffSchema = yup.object({
	firstName: yup.string().trim().label('first name').required(),
	lastName: yup.string().trim().label('last name').required(),
	contact: yup.string().trim().max(15).min(10).required(),
	gender: yup.string().trim().required(),
});
