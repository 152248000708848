import { Box, Paper } from "@mantine/core";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import EditClinicForm from "./components/edit-clinic";
import { useQuery } from "@tanstack/react-query";
import { ModeStateProp } from "../../helpers/props";
import { getAllConsultations } from "../../helpers/api";
import { consultationColorCoding, percentage } from "../../helpers/utilities";
import { Chip } from "@mui/material";
import _ from "lodash";
import { SearchInput } from "../../components/shared";
import { IconCategory2 } from "@tabler/icons-react";
import FiltersBoard from "./components/filters-board";
import { IConsultation } from "../../helpers/interfaces";

const ConsultationsPage = () => {
  const [filteredData, setFilteredData] = useState([]);
  const [mode, setMode] = useState<Partial<ModeStateProp>>();
  const [showFilters, setFilters] = useState<boolean>();

  // queries
  const {
    data: consultations = [],
    isFetching,
    refetch,
  } = useQuery({
    queryFn: () =>
      getAllConsultations().then((data) => {
        if (data) {
          setFilteredData(data);
          return data;
        }
      }),
    queryKey: ["all-consultations"],
  });

  const handleOpen = (id: string) => {
    setMode({ edit: true, id });
  };

  return (
    <section className="pb-4">
      {mode?.edit ? (
        <Paper>
          <EditClinicForm
            canEdit={true}
            id={mode?.id}
            onUpdate={refetch}
            onClose={() => setMode({ edit: false, id: "" })}
          />
        </Paper>
      ) : (
        <>
          <div className="row my-3">
            <div className="col-12 col-md-9">
              <div className="d-flex">
                <SearchInput
                  onChange={(v) => {
                    setFilteredData(
                      consultations?.filter(
                        (con: IConsultation) =>
                          con?.type.toLowerCase().includes(v) ||
                          con?.code.toLowerCase().includes(v) ||
                          con?.branch?.branchName.toLowerCase().includes(v) ||
                          con?.branch?.facility.facilityName
                            .toLowerCase()
                            .includes(v) ||
                          con?.doctor?.firstName.toLowerCase().includes(v) ||
                          con?.doctor?.lastName.toLowerCase().includes(v)
                      )
                    );
                  }}
                  placeholder="search consultations"
                />
              </div>
            </div>
            <div className="col-12 col-md-3 d-flex justify-content-end align-items-center">
              <div>
                <Chip className="ms-2" label={filteredData?.length || 0} />
                <span className="text-black ms-2">Consultations</span>
              </div>
              <button
                onClick={() => setFilters(!showFilters)}
                className={`ms-2 button is-light ${showFilters && " btn-prim"}`}
              >
                <IconCategory2 />
              </button>
            </div>
          </div>
          {showFilters && (
            <div className="py-3">
              <FiltersBoard />
            </div>
          )}
          <Paper>
            <Box style={{ height: 500, width: "100%" }}>
              <DataGrid
                rows={filteredData}
                columns={[
                  {
                    field: "id",
                    headerName: "#",
                    sortable: true,
                    width: 50,
                    renderCell: (params: any) =>
                      params.api.getRowIndexRelativeToVisibleRows(
                        params.row.id
                      ) + 1,
                  },
                  {
                    field: "branch",
                    headerName: "Facility Name",
                    sortable: true,
                    width: 250,
                    renderCell: (params) =>
                      `${params.value?.facility?.facilityName} (${params.value?.branchName})`,
                  },
                  {
                    field: "createdAt",
                    headerName: "Date",
                    sortable: true,
                    width: 150,
                    renderCell: (params) =>
                      new Date(params.value).toLocaleDateString(),
                  },
                  {
                    field: "code",
                    headerName: "Code",
                    sortable: true,
                    width: 150,
                    renderCell: (params: any) => (
                      <p className="me-2">
                        <kbd
                          className={consultationColorCoding(
                            params?.row?.completedTasks,
                            params?.row?.totalTasks
                          )}
                        >
                          {params?.value}
                        </kbd>
                        <span className="ms-1">
                          -{" "}
                          {percentage(
                            params?.row?.completedTasks,
                            params?.row?.totalTasks
                          )}
                          %
                        </span>
                      </p>
                    ),
                  },
                  {
                    field: "type",
                    headerName: "Type",
                    sortable: true,
                    width: 120,
                    valueFormatter: (value: any) => _.capitalize(value),
                  },
                  {
                    field: "doctor",
                    headerName: "Staff",
                    sortable: true,
                    flex: 1,
                    renderCell: (params) =>
                      `${params.value.firstName} ${params.value.lastName}`,
                  },
                  {
                    field: "#",
                    headerName: "",
                    width: 100,
                    renderCell: (row: any) => {
                      return (
                        <button
                          className="button is-ghost mt-1"
                          // onClick={() => handleOpen(row.id)}
                        >
                          <span className="bi bi-eye me-2" />
                          view
                        </button>
                      );
                    },
                  },
                ]}
                loading={isFetching}
              />
            </Box>
          </Paper>
        </>
      )}
    </section>
  );
};

export { ConsultationsPage };
