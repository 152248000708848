import Tile from "../../components/pages/tile";
import PageTitle from "../../components/page-title";
import { SectionHeader } from "../../components/shared";
import { Divider } from "antd";
import { subnavs } from "../../helpers/config";

const SetupIndex = () => {
  return (
    <section className="mt-3">
      <SectionHeader
        className="my-4"
        title="Control Panel"
        description="Admin and system-wide configurations"
      />
      <Divider />
      <div className="row">
        {subnavs.controlPanel.map((nav) => (
          <div className="mb-3 col-md-4 col-12" key={nav.name}>
            <Tile
              orientation="horizontal"
              isAction
              title={nav.name}
              description={nav.info}
              icon={nav.icon}
              url={nav.url}
            />
          </div>
        ))}
      </div>
    </section>
  );
};

export { SetupIndex };
