import { Box, Paper } from "@mantine/core";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Chip } from "@mui/material";
import _ from "lodash";
import { IconCategory2 } from "@tabler/icons-react";
import { ModeStateProp } from "../../../helpers/props";
import { SearchInput } from "../../../components/shared";
import { IContact } from "../../../helpers/interfaces";
import { getAllContactUs } from "../../../helpers/api";
import EditContactUsComponent from "./edit-message";
import FiltersBoard from "../../core/components/filters-board";
import { toastMsg } from "../../../helpers/utilities";

const AllContactUsPage = () => {
  const [filteredData, setFilteredData] = useState([]);
  const [mode, setMode] = useState<Partial<ModeStateProp>>();
  const [showFilters, setFilters] = useState<boolean>();

  // queries
  const {
    data: contacts = [],
    isFetching,
    refetch,
  } = useQuery({
    queryFn: () =>
      getAllContactUs().then((data) => {
        if (data) {
          setFilteredData(data);
          return data;
        }
      }),
    queryKey: ["all-contact-us"],
  });

  const handleOpen = (id: string) => {
    setMode({ edit: true, id });
  };

  const trashIt = (id: string) => {
    toastMsg("Woah... relax. Not yet homie!", "error");
  };

  return (
    <section className="pb-4">
      {mode?.edit ? (
        <Paper>
          <EditContactUsComponent
            canEdit={true}
            id={mode?.id}
            onUpdate={refetch}
            onClose={() => setMode({ edit: false, id: "" })}
          />
        </Paper>
      ) : (
        <>
          <div className="row my-3">
            <div className="col-12 col-md-6">
              <div className="d-flex">
                <SearchInput
                  onChange={(v) => {
                    setFilteredData(
                      contacts?.filter(
                        (cont: Partial<IContact>) =>
                          cont?.contact?.toLowerCase().includes(v) ||
                          cont?.email?.toLowerCase().includes(v) ||
                          cont?.fullName?.toLowerCase().includes(v) ||
                          cont?.message?.toLowerCase().includes(v)
                      )
                    );
                  }}
                  placeholder="search messages"
                />
              </div>
            </div>
            <div className="col-12 col-md-6 d-flex justify-content-end align-items-center">
              <div>
                <Chip label={filteredData?.length || 0} />
                <span className="text-black ms-2">Messages</span>
              </div>
              <button
                onClick={() => setFilters(!showFilters)}
                className={`ms-2 button is-light ${showFilters && " btn-prim"}`}
              >
                <IconCategory2 />
              </button>
            </div>
          </div>
          {showFilters && (
            <div className="py-3">
              <FiltersBoard />
            </div>
          )}
          <Paper>
            <Box style={{ height: 500, width: "100%" }}>
              <DataGrid
                rows={filteredData}
                columns={[
                  {
                    field: "id",
                    headerName: "#",
                    sortable: true,
                    width: 50,
                    renderCell: (params: any) =>
                      params.api.getRowIndexRelativeToVisibleRows(
                        params.row.id
                      ) + 1,
                  },
                  {
                    field: "createdAt",
                    headerName: "Date",
                    sortable: true,
                    width: 150,
                    renderCell: (params) =>
                      new Date(params.value).toDateString(),
                  },
                  {
                    field: "name",
                    headerName: "Sender name",
                    sortable: true,
                    width: 170,
                  },
                  {
                    field: "contact",
                    headerName: "Phone",
                    sortable: true,
                    width: 150,
                  },
                  {
                    field: "message",
                    headerName: "Message",
                    sortable: true,
                    // width: 150,
                    flex: 1,
                  },
                  {
                    field: "#",
                    headerName: "",
                    width: 210,
                    renderCell: (row: any) => {
                      return (
                        <>
                          <button
                            className="button is-ghost mt-1"
                            // onClick={() => handleOpen(row.id)}
                          >
                            <span className="bi bi-folder2-open me-2" />
                            read
                          </button>
                          <button
                            className="button text-danger is-ghost mt-1"
                            // onClick={() => trashIt(row.id)}
                          >
                            <span className="bi bi-trash me-2" />
                            trash!
                          </button>
                        </>
                      );
                    },
                  },
                ]}
                loading={isFetching}
              />
            </Box>
          </Paper>
        </>
      )}
    </section>
  );
};

export { AllContactUsPage };
