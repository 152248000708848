import { decryptString, encryptString } from "./utilities";
import {
	roleEncryptionKey,
	tokenEncryptionKey,
	userEncryptionKey,
} from "./config";
import _ from "lodash";
import { getRefreshToken } from './api'
import { IUser } from "./interfaces";

const authenticate = (token: string, user: IUser) => {
	if (token) {
		const eToken = encryptString(token, tokenEncryptionKey!);
		sessionStorage.setItem("rvcoadmTk", eToken);
	}

	if (user) {
		setRole(user?.role);
		const encryptedUser = encryptString(JSON.stringify(user), userEncryptionKey!);
		sessionStorage.setItem("rvcoadmUsr", encryptedUser);
	}
};

const getToken = () => {
	return decryptString(sessionStorage.getItem("rvcoadmTk")!, tokenEncryptionKey!);
};

const refreshToken = async () => {
	const { token, user } = await getRefreshToken()
	logOut()
	authenticate(token, user)
};

const getUser = () => {
	const user = sessionStorage.getItem("rvcoadmUsr");
	if (!user) return;

	return JSON.parse(decryptString(user, userEncryptionKey!)) as IUser;
};

const logOut = () => {
	sessionStorage.removeItem("rvcoadmTk");
	sessionStorage.removeItem("rvcoadmUsr");
	removeRole();
};

const isAuthenticated = () => {
	const token = sessionStorage.getItem("rvcoadmTk");
	return !_.isEmpty(token) ? true : false;
};

const setRole = (role: string) => {
	const r = encryptString(role, roleEncryptionKey!);
	sessionStorage.setItem("rvcoadmRl", r);
};

const removeRole = () => {
	sessionStorage.removeItem("rvcoadmRl");
};

const getRole = () => {
	const r = sessionStorage.getItem("rvcoadmRl");
	return decryptString(r!, roleEncryptionKey!);
};

const isAdmin = (userId: string) => {
	return getUser()!.id === userId;
};


export {
	authenticate,
	isAuthenticated,
	getToken,
	refreshToken,
	logOut,
	setRole,
	getRole,
	removeRole,
	getUser,
	isAdmin,
};
