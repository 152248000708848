import { Helmet } from "react-helmet";
import { constants } from "../helpers/config";
import { PageTitleProps } from "../helpers/props";

const PageTitle = ({ title }: PageTitleProps) => {
  return (
    <Helmet>
      <title>
        {title} - {constants.siteTitle}
      </title>
    </Helmet>
  );
};

export default PageTitle;
