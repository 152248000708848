import { PageHeader } from "../../components/shared";
import { useState } from "react";
import { Center, SegmentedControl } from "@mantine/core";
import { IconMessage, IconPlus } from "@tabler/icons-react";
import { AllContactUsPage } from "./components/contact-us";
import { AllRegistrations } from "./components/registrations";

const IncomingPanelPage = () => {
  const [tab, setTab] = useState("contactUs");

  return (
    <section className="mt-3">
      <PageHeader
        title="Incoming"
        description="Contact-us and facility registrations"
      />
      <SegmentedControl
        className="mb-2"
        size="md"
        value={tab}
        onChange={setTab}
        data={[
          {
            value: "contactUs",
            label: (
              <Center>
                <IconMessage color="teal" className="me-2" />
                <span className="d-none d-md-inline">Contact Us</span>
              </Center>
            ),
          },
          {
            value: "registrations",
            label: (
              <Center>
                <IconPlus color="teal" className="me-2" />
                <span className="d-none d-md-inline">
                  Facility Registrations
                </span>
              </Center>
            ),
          },
        ]}
      />
      {tab === "contactUs" && <AllContactUsPage key="contact-us" />}
      {tab === "registrations" && <AllRegistrations key="registrations" />}
    </section>
  );
};

export { IncomingPanelPage };
