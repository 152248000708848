import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
} from "recharts";
import { IRecentConsultationsProp } from "../../../helpers/props";
import { format } from "date-fns";

export default function RecentConsultationsChart(props: {
  consultations: IRecentConsultationsProp[];
}) {
  const chartDta = props.consultations?.map(
    (cons: IRecentConsultationsProp) => {
      return {
        period: format(new Date(cons.date), "MMMM d"),
        consultations: cons.count,
      };
    }
  );

  return (
    <BarChart width={1300} height={450} className="w-100 h-20" data={chartDta}>
      <XAxis dataKey="period" stroke="#028cc9" />
      <YAxis />
      <Tooltip wrapperStyle={{ width: 150, backgroundColor: "#ccc" }} />
      <Legend
        width={130}
        wrapperStyle={{
          // top: 40,
          backgroundColor: "#f5f5f5",
          border: "1px solid #d5d5d5",
          borderRadius: 3,
          lineHeight: "40px",
        }}
      />
      <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
      <Bar dataKey="consultations" fill="#028cc9" barSize={30} />
    </BarChart>
    // <Line
    //   data={{
    //     labels,
    //     datasets: [
    //       {
    //         label: "Recent Consultations Metrics",
    //         data: values,
    //         borderColor: "rgb(53, 162, 235)",
    //         backgroundColor: "rgba(53, 162, 235, 0.5)",
    //       },
    //     ],
    //   }}
    // />
  );
}
