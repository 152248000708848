
export const constants = {
	siteTitle: "Rivia Admin",
};

export const appSources = {
	demo: 'demo',
	live: 'live'
}

export const dateFormat = "MM d yyyy";
export const appSource = localStorage.getItem('app-source')

// envs
export const tokenEncryptionKey = process.env.REACT_APP_TOKEN_KEY;
export const userEncryptionKey = process.env.REACT_APP_USER_KEY;
export const roleEncryptionKey = process.env.REACT_APP_ROLE_KEY;
export const appUrl = appSource === appSources.live ? process.env.REACT_APP_API_URL : process.env.REACT_APP_DEMO_API_URL

// screen widths
export const smallDevice = '600' //or 576 //extra small devices are below & small are above
export const mediumDevice = '768'
export const largeDevice = '992'
export const xlargeDevice = '1200'
export const xxlargeDevice = '1400'


// routing configs
export const appLinks = {
	home: "/",

	core: {
		index: "/core",
	},

	express: {
		index: "/express",
	},

	reports: {
		index: "/reports",
	},

	controlPanel: {
		index: "/control-panel",
		app: "app",
		security: "security",
		facilities: "facilities",
		incoming: "incoming",
	},

	switchFacility: "/switch-facility",

	// auth
	login: "/login",
	// signup: "/signup",
	changePassword: (hash: string) => `/change-password/${hash}`,
	resetpassword: "/reset-password",

	// not found
	catchAll: "*",
};


export const subnavs = {
	core: [
		{
			name: "sell",
			icon: "bi-basket",
			info: 'brief description here',
			url: appLinks.core.index,
		},

	],
	express: [
		{
			name: "inventory",
			icon: "bi-box-seam",
			info: 'brief description here',
			url: appLinks.express.index
		},
	],
	reporting: [
		{
			name: "sales",
			icon: "bi-bag",
			info: 'brief description here',
			url: appLinks.reports.index,
		},
	],
	controlPanel: [
		{
			name: "system",
			icon: "bi-gear",
			info: 'configure system-wide operations',
			url: appLinks.controlPanel.index,
		},
		{
			name: "facilities",
			icon: "bi-shop",
			info: 'manage how facilities access and use RiviaOS',
			url: appLinks.controlPanel.index,
		},
		{
			name: "security",
			icon: "bi-shield-lock",
			info: 'secure and manage application access',
			url: appLinks.controlPanel.index,
		},
		{
			name: "incoming",
			icon: "bi-download",
			info: 'received messages from public channels',
			url: appLinks.controlPanel.incoming,
		},
	],
};



