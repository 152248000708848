import { Skeleton } from "@mantine/core";
import PageTitle from "../../components/page-title";
import { SummaryCard } from "../../components/pages/tile";
import { useQuery } from "@tanstack/react-query";
import _ from "lodash";
import { getUser } from "../../helpers/auth";
import {
  IconBuilding,
  IconCalendar,
  IconHeart,
  IconMedicineSyrup,
} from "@tabler/icons-react";
import { LiveDemoComponent } from "../../components/shared";
import { getSummaries } from "../../helpers/api";
import RecentConsultationsChart from "./components/chart";

const HomeIndex = () => {
  const user = getUser();

  // queries
  const { data: summary = {}, isFetching } = useQuery({
    queryFn: () => getSummaries(),
    queryKey: ["admin-summaries"],
  });

  return (
    <section className="my-3">
      <PageTitle title="Home" />
      <div className="d-flex justify-content-between">
        <div>
          <h5 className="page-title mb-0">Home</h5>
          <p className="title-4">
            <b>{user?.name?.split(" ")[0] || "User"}</b>, what do you wanna do
            today?
          </p>
        </div>
        <div className="d-inline d-md-none">
          <LiveDemoComponent />
        </div>
      </div>

      <div className="my-2 row">
        <div className="col-md-3 col-6 mb-2">
          <SummaryCard
            label={
              <>
                <span className="d-none d-md-inline">Consultations</span>
                <span className="d-inline d-md-none">Consults</span>
              </>
            }
            icon={<IconHeart color="teal" />}
            data={
              <Skeleton visible={isFetching}>
                {!_.isEmpty(summary) ? summary?.consultations[0]?.count : 0}
              </Skeleton>
            }
          />
        </div>
        <div className="col-md-3 col-6 mb-2">
          <SummaryCard
            label={
              <>
                <span className="d-none d-md-inline">Express Sessions</span>
                <span className="d-inline d-md-none">Expresses</span>
              </>
            }
            icon={<IconMedicineSyrup color="teal" />}
            data={<Skeleton visible={isFetching}>0</Skeleton>}
          />
        </div>
        <div className="col-md-3 col-6">
          <SummaryCard
            label={
              <>
                <span className="d-none d-md-inline">Appointments</span>
                <span className="d-inline d-md-none">Schedules</span>
              </>
            }
            icon={<IconCalendar color="teal" />}
            data={
              <Skeleton visible={isFetching}>
                {!_.isEmpty(summary) ? summary?.appointments[0]?.count : 0}
              </Skeleton>
            }
          />
        </div>
        <div className="col-md-3 col-6">
          <SummaryCard
            label="Facilities"
            icon={<IconBuilding color="teal" />}
            data={
              <Skeleton visible={isFetching}>
                {!_.isEmpty(summary) ? summary?.facilities[0]?.count : 0}
              </Skeleton>
            }
          />
        </div>
      </div>
      <div className="mt-5">
        <RecentConsultationsChart
          consultations={summary?.recentConsultations}
        />
        {/* <li>top patronized services table here</li> */}
      </div>
    </section>
  );
};

export { HomeIndex };
