import { Link, NavLink, useNavigate } from "react-router-dom";
import { appLinks } from "../helpers/config";
import { Menu } from "@mantine/core";
import { getUser, logOut, refreshToken } from "../helpers/auth";
import {
  IconBell,
  IconHome,
  IconLock,
  IconPower,
  IconReport,
  IconSettings,
  IconSwitchHorizontal,
  IconTarget,
  IconUser,
  IconWind,
} from "@tabler/icons-react";
import { putLogout } from "../helpers/api";
import logo from "../static/img/rivia-icon.png";

import { maskEmail, toastMsg } from "../helpers/utilities";
import { toast } from "react-toastify";
import { Chip } from "@mui/material";
import { LiveDemoComponent } from "./shared";
import _ from "lodash";

const PrimaryNav = () => {
  const nav = useNavigate();
  const user = getUser();

  return (
    <nav className="shadow primary-nav">
      <Link to={appLinks.home} className="logo ms-2 pe-4">
        <img src={logo} width={25} alt="Rivia Admin Logo" />
      </Link>
      <div className="nav-links text-md-start text-center mt-1">
        <NavLink
          className={(props) => {
            return `${props.isActive ? "active-nav " : ""}`;
          }}
          end
          to={appLinks.home}
        >
          {/* <span className="h5 bi bi-house"></span> */}
          <IconHome className="h5 me-md-2" />
          <span className="d-none d-md-inline-block">Home</span>
        </NavLink>
        <NavLink
          className={(props) => {
            return `${props.isActive ? "active-nav " : ""}`;
          }}
          end
          to={appLinks.core.index}
        >
          <IconTarget className="h5 me-md-2" />
          <span className="d-none d-md-inline-block">Core</span>
        </NavLink>
        {/* <NavLink
          className={(props) => {
            return `${props.isActive ? "active-nav " : ""}`;
          }}
          end
          to={appLinks.express.index}
        >
          <IconWind className="h5 me-md-2" />
          <span className="d-none d-md-inline-block">Express</span>
        </NavLink> */}
        {/* <NavLink
          className={(props) => {
            return `${props.isActive ? "active-nav " : ""}`;
          }}
          end
          to={appLinks.reports.index}
        >
          <IconReport className="h5 me-md-2" />
          <span className="d-none d-md-inline-block">Reporting</span>
        </NavLink> */}
        <NavLink
          className={(props) => {
            return `${props.isActive ? "active-nav " : ""}`;
          }}
          end
          to={appLinks.controlPanel.index}
        >
          <IconSettings className="h5 me-md-2" />
          <span className="d-none d-md-inline-block">Control Panel</span>
        </NavLink>
      </div>
      <div style={{ zIndex: 2500 }} className="d-flex hover">
        <div className="d-none d-md-block me-3">
          <LiveDemoComponent />
        </div>
        {/* <IconBell className="mx-2" /> */}
        <Menu>
          <Menu.Target>
            <IconUser />
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Label>User</Menu.Label>
            <div className="p-2">
              <strong className="text-secondary">{user?.name}</strong>
              <div className="text-muted">
                <i className="d-block">{maskEmail(user!.email)}</i>
                <Chip size="small" label={_.capitalize(user?.role)} />
              </div>
            </div>
            <Menu.Divider />
            <Menu.Label>Security</Menu.Label>
            {/* <Menu.Item
                            py={ 6 }
                            onClick={ () => onOpen( row.id ) }
                            icon={ <IconUser size={ 20 } /> }>
                            Account
                        </Menu.Item> */}

            <Menu.Item
              py={6}
              onClick={() => {
                logOut();
                nav(appLinks.resetpassword);
              }}
              // icon={<IconLock size={20} />}
            >
              Reset Password
            </Menu.Item>
            <Menu.Item
              py={6}
              color="red"
              onClick={() => {
                putLogout(user!.id);
                logOut();
                window.location.href = appLinks.login;
              }}
              // icon={<IconPower size={20} />}
            >
              Log Out
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </div>
    </nav>
  );
};

export { PrimaryNav };
