import axios from "axios";
import { getToken } from "./auth";
import { appUrl } from "./config";
import { ILogin, IOnboardFacility, IOnboardStaff } from "./interfaces";

export const fetchQuery = async (url = "", auth: boolean = true, config: {} = {}) => {
	try {
		if (auth) axios.defaults.headers.common["token"] = getToken();

		const res = await axios.get(`${appUrl}/${url}`, config);
		return res;
	} catch (ex) {
		return ex;
	}
};

export const postQuery = async (url = "", data = {}, auth: boolean = true, config: {} = {}) => {
	try {
		if (auth) axios.defaults.headers.common["token"] = getToken();

		const res = await axios.post(`${appUrl}/${url}`, data, config);
		return res;
	} catch (ex) {
		return ex;
	}
};

export const updateQuery = async (url = "", data = {}, auth: boolean = true, config: {} = {}) => {
	try {
		if (auth) axios.defaults.headers.common["token"] = getToken();

		const res = await axios.put(`${appUrl}/${url}`, data, config);
		return res;
	} catch (ex) {
		return ex;
	}
};

export const patchQuery = async (url = "", data = {}, auth: boolean = true, config: {} = {}) => {
	try {
		if (auth) axios.defaults.headers.common["token"] = getToken();

		const res = await axios.patch(`${appUrl}/${url}`, data, config);
		return res;
	} catch (ex) {
		return ex;
	}
};

export const deleteQuery = async (url = "", auth: boolean = true,) => {
	try {
		if (auth) axios.defaults.headers.common["token"] = getToken();

		const res = await axios.delete(`${appUrl}/${url}`);
		return res;
	} catch (ex) {
		return ex;
	}
};



// AUTH
export const getUser = async (id: string) => {
	const qry = (await fetchQuery(`accounts/${id}`)) as any;
	return qry?.data?.data;
};

export const getAllUsers = async () => {
	const qry = (await fetchQuery("/")) as any;
	return qry?.data?.data;
};

export const postAddUser = async (user: {}) => {
	return await postQuery("add-user", user);
};

export const postLogin = async (user: ILogin) => {
	return await postQuery("login", user, false);
};

export const postResetPassword = async (email: string) => {
	return await postQuery("reset-password", { email }, false);
};

export const putLogout = async (user_id: string) => {
	return await updateQuery(`${user_id}/logout`);
};

export const putChangePassword = async (user: {}) => {
	return await updateQuery("change-password", user, false);
};

export const putToggleUserStatus = async (id: string) => {
	return await updateQuery(`${id}/toggle-status`);
};

export const putUser = async (data: {}) => {
	return await updateQuery('/', data);
};

export const getRefreshToken = async () => {
	const qry = (await fetchQuery(`refresh - token`)) as any;
	return qry?.data;
};



// FACILITIES
export const getFacility = async (id: string) => {
	const qry = (await fetchQuery(`facilities/${id} `)) as any;
	return qry?.data?.data;
};

export const getAllFacilities = async () => {
	const qry = (await fetchQuery(`facilities`)) as any;
	return qry?.data?.data;
};

export const postFacility = async (data: Partial<{ facility: IOnboardFacility, staff: IOnboardStaff }>) => {
	return await postQuery("facilities", data);
};

export const putFacility = async (data: Partial<{ facility: IOnboardFacility, staff: IOnboardStaff }>) => {
	return await updateQuery(`facilities`, data);
};

export const deleteFacility = async (id: string) => {
	return await deleteQuery(`facilities / ${id} `);
};




// CONSULTATIONS
export const getConsultation = async (id: string) => {
	const qry = (await fetchQuery(`consultations/${id} `)) as any;
	return qry?.data?.data;
};

export const getAllConsultations = async () => {
	const qry = (await fetchQuery(`consultations`)) as any;
	return qry?.data?.data;
};



// MISC
export const getAllLogs = async () => {
	const qry = (await fetchQuery(`partner-logs`)) as any;
	return qry?.data?.data;
};

export const getAllExpenses = async () => {
	const qry = (await fetchQuery(`expenses`)) as any;
	return qry?.data?.data;
};

export const getAllContactUs = async () => {
	const qry = (await fetchQuery(`contact-us`)) as any;
	return qry?.data?.data;
};

export const getAllAppointments = async () => {
	const qry = (await fetchQuery(`appointments`)) as any;
	return qry?.data?.data;
};

export const getAllRegistrations = async () => {
	const qry = (await fetchQuery(`facility-registrations`)) as any;
	return qry?.data?.data;
};

export const getSummaries = async () => {
	const qry = (await fetchQuery(`summary-data`)) as any;
	return qry?.data?.data;
};