import { Box, Paper } from "@mantine/core";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import EditClinicForm from "./components/edit-clinic";
import { useQuery } from "@tanstack/react-query";
import { ModeStateProp } from "../../helpers/props";
import { getAllLogs } from "../../helpers/api";
import { Chip } from "@mui/material";
import _ from "lodash";
import { SearchInput } from "../../components/shared";
import {
  IconCategory2,
  IconCheck,
  IconChecks,
  IconInfoCircle,
  IconShield,
} from "@tabler/icons-react";
import FiltersBoard from "./components/filters-board";
import { Ilog } from "../../helpers/interfaces";
import { format, isToday, isYesterday } from "date-fns";

const PartnerActivityLogs = () => {
  const [filteredData, setFilteredData] = useState([]);
  const [showFilters, setFilters] = useState<boolean>();

  // queries
  const {
    data: activityLogs = [],
    isFetching,
    refetch,
  } = useQuery({
    queryFn: () =>
      getAllLogs().then((data) => {
        if (data) {
          setFilteredData(data);
          return data;
        }
      }),
    queryKey: ["all-activity-logs"],
  });

  return (
    <section className="pb-4">
      <div className="row my-3">
        <div className="col-12 col-md-6">
          <div className="d-flex">
            <SearchInput
              onChange={(v) => {
                setFilteredData(
                  activityLogs?.filter(
                    (log: Partial<Ilog>) =>
                      log?.description?.toLowerCase().includes(v) ||
                      log?.department?.toLowerCase().includes(v) ||
                      log?.logType?.toLowerCase().includes(v) ||
                      log?.branch?.branchName?.toLowerCase().includes(v) ||
                      log?.branch?.facility.facilityName
                        ?.toLowerCase()
                        .includes(v)
                  )
                );
              }}
              placeholder="search all logs"
            />
          </div>
        </div>
        <div className="col-12 col-md-6 d-flex justify-content-end align-items-center">
          <span className="me-2">
            <small>
              <IconInfoCircle size={15} color="blue" /> - info
            </small>
            <small className="mx-2">
              <IconChecks size={15} color="green" /> - alert
            </small>
            <small>
              <IconShield size={15} color="red" /> - security
            </small>
          </span>
          <div>
            <Chip className="ms-2" label={filteredData?.length || 0} />
            <span className="text-black ms-2">Logs</span>
          </div>
          <button
            onClick={() => setFilters(!showFilters)}
            className={`ms-2 button is-light ${showFilters && " btn-prim"}`}
          >
            <IconCategory2 />
          </button>
        </div>
      </div>
      {showFilters && (
        <div className="py-3">
          <FiltersBoard />
        </div>
      )}
      <Paper>
        <Box style={{ height: 500, width: "100%" }}>
          <DataGrid
            rows={filteredData}
            columns={[
              {
                field: "id",
                headerName: "#",
                sortable: true,
                width: 50,
                renderCell: (params: any) =>
                  params.api.getRowIndexRelativeToVisibleRows(params.row.id) +
                  1,
              },
              {
                field: "branch",
                headerName: "Facility Name",
                sortable: true,
                width: 200,
                renderCell: (params) =>
                  `${params.value?.facility?.facilityName} (${params.value?.branchName})`,
              },
              {
                field: "user",
                headerName: "Staff",
                sortable: true,
                width: 150,
                renderCell: (params) =>
                  `${params.value?.staff?.firstName} ${params.value?.staff?.lastName}`,
              },
              {
                field: "createdAt",
                headerName: "Date",
                sortable: true,
                width: 130,
                renderCell: (params) =>
                  `${
                    isToday(new Date(params?.value))
                      ? "Today "
                      : isYesterday(new Date(params?.value))
                      ? "Yesterday "
                      : format(new Date(params?.value), "MMM dd, YYY")
                  }`,
              },
              {
                field: "",
                headerName: "Time",
                sortable: true,
                width: 115,
                renderCell: (params: any) =>
                  new Date(params?.row?.createdAt).toLocaleTimeString(),
              },
              {
                field: "description",
                headerName: "Activity",
                sortable: true,
                // width: 500,
                flex: 1,
                renderCell: (params: any) => {
                  const icon =
                    params?.row?.logType === "security" ? (
                      <IconShield color="red" size={15} />
                    ) : params?.row?.logType === "alert" ? (
                      <IconInfoCircle color="blue" size={15} />
                    ) : (
                      <IconChecks color="green" size={15} />
                    );

                  return (
                    <>
                      {icon} - {params.value}
                    </>
                  );
                },
              },
              // {
              //   field: "department",
              //   headerName: "Department",
              //   sortable: true,
              //   width: 100,
              // },
              // {
              //   field: "logType",
              //   headerName: "Type",
              //   sortable: true,
              //   width: 100,
              // },
            ]}
            loading={isFetching}
          />
        </Box>
      </Paper>
    </section>
  );
};

export { PartnerActivityLogs };
