import { Link } from "react-router-dom";
import _ from "lodash";
import { Tooltip } from "@mui/material";
import { SummaryCardProps, TileProps } from "../../helpers/props";

const Tile = ({
  title,
  icon,
  url,
  className,
  isLink = true,
  isAction = false,
  isActive = false,
  orientation = "vertical",
  description,
  onClick,
}: Partial<TileProps>) => {
  const verticalContent = (
    <div className="py-4">
      <span className={`h5 bi ${icon} mb-3`}></span>
      <p className="m-0">{title}</p>
    </div>
  );
  const horizontalContent = (
    <div className="row p-4">
      <div className="col-3">
        <span className={`h3 bi ${icon} mb-3`}></span>
      </div>
      <div className="col-9 text-left">
        <h5 className="m-0">{title}</h5>
        <small>{description}</small>
      </div>
    </div>
  );

  return (
    <>
      {orientation === "vertical" ? (
        <div
          onClick={onClick}
          className={`app-tile ${isAction && " action-tile "} ${
            isActive && "tile-active"
          } ${className}`}
        >
          {isLink ? (
            <Link to={url || "#"}>{verticalContent}</Link>
          ) : (
            <>{verticalContent}</>
          )}
        </div>
      ) : (
        <div
          onClick={onClick}
          className={`app-tile ${isAction && " action-tile "} ${
            isActive && "tile-active"
          } ${className}`}
        >
          {isLink ? (
            <Link
              style={{
                textDecoration: "none",
              }}
              to={url || "#"}
            >
              {horizontalContent}
            </Link>
          ) : (
            <>{horizontalContent}</>
          )}
        </div>
      )}
    </>
  );
};

export const SummaryCard = ({
  label,
  data = 0,
  isLink = false,
  url,
  icon,
  className,
  alert = false,
  danger = false,
  warning = false,
}: Partial<SummaryCardProps>) => {
  const content = (
    <Tooltip title={label}>
      <div className="p-4">
        <div className="d-flex justify-content-between">
          <p className="m-0 card-label">{label}</p>
          {typeof icon === "string" ? (
            <span className={`ms-2 bi ${icon}`} />
          ) : (
            icon
          )}
        </div>
        <h3 className="h3 my-0">{data}</h3>
      </div>
    </Tooltip>
  );
  return (
    <div
      className={`summary-card ${alert && " card-alert "} ${
        danger && " card-danger "
      } ${warning && " card-warning "} ${className}`}
    >
      {isLink ? <Link to={url || "#"}>{content}</Link> : <>{content}</>}
    </div>
  );
};

export default Tile;
