import { useNavigate } from "react-router-dom";
import { appLinks, appSource, appSources } from "../helpers/config";
import PageTitle from "./page-title";
import { Chip } from "@mui/material";
import {
  HomeButtonProps,
  PageHeaderProps,
  SearchInputProps,
  SectionHeaderProps,
} from "../helpers/props";
import { alertDialog, toastMsg } from "../helpers/utilities";

const BackButton = ({ className, label = "Back" }: HomeButtonProps) => {
  const navigate = useNavigate();

  return (
    <button
      className={`bokx-btn text-white ${className}`}
      onClick={() => navigate(-1)}
    >
      <span className="me-md-2 bi bi-arrow-left" />
      <span className="d-none d-md-inline-block">{label}</span>
    </button>
  );
};

const HomeButton = ({ className = "", label = "home" }: HomeButtonProps) => {
  const navigate = useNavigate();

  return (
    <button
      className={`bokx-btn text-white ${className}`}
      onClick={() => navigate(appLinks.home, { replace: false })}
    >
      <span className="me-md-2 bi bi-house" />
      <span className="d-none d-md-inline-block">{label}</span>
    </button>
  );
};

const RequiredIndicator = () => {
  return <small className="text-danger ms-1">*</small>;
};

const SectionHeader = ({
  title,
  description,
  className,
}: Partial<SectionHeaderProps>) => {
  return (
    <div className={`d-flex justify-content-between ${className}`}>
      <PageTitle title={title} />
      <div>
        <h5 className="mb-0 page-title">{title}</h5>
        <p className="mt-0 text-muted">{description}</p>
      </div>
      <div>
        <HomeButton />
      </div>
    </div>
  );
};

const PageHeader = ({
  title,
  description,
  className,
  metaData,
}: Partial<PageHeaderProps>) => {
  //page header
  return (
    <div className={`d-flex justify-content-between ${className}`}>
      <PageTitle title={title} />
      <div>
        <h5 className="mb-0 page-header">
          {title}
          {metaData && <Chip className="ms-2" label={metaData} />}
        </h5>
        <p className="mt-0 text-muted">{description}</p>
      </div>
      <div>
        <BackButton />
      </div>
    </div>
  );
};

const SaveButton = (title = "Save", icon = "bi-save", click: () => void) => {
  return (
    <button className="bokx-btn btn-prim" onClick={click}>
      <span className={`bi ${icon} me-2`}> </span>
      {title}
    </button>
  );
};

const SearchInput = ({
  placeholder,
  onChange,
  autoFocus,
  className,
}: Partial<SearchInputProps>) => {
  return (
    <div className={`field ${className}`}>
      <div className="control has-icons-left has-icons-right">
        <input
          className="input"
          autoFocus={autoFocus}
          type="search"
          placeholder={placeholder}
          onChange={(e) => onChange!(e.target.value)}
        />
        <span className="icon is-small is-left">
          <i className="bi bi-search"></i>
        </span>
      </div>
    </div>
  );
};

const DetailTile = (
  title: string,
  detail: string,
  icon: React.ReactNode,
  className: string,
  firstCol: string,
  secondCol: string
) => {
  return (
    <div className={`row ${className}`}>
      <div className={`${firstCol ?? "col-md-3"} col`}>
        <span className={`bi bi-${icon} h5 me-3`}></span>
        <strong>{title}</strong>
      </div>
      <div className={`${secondCol ?? "col-md-9"} col`}>{detail}</div>
    </div>
  );
};

const Copyright = () => {
  return <>&copy; {new Date().getFullYear()} RiviaCo</>;
};

const LiveDemoComponent = () => {
  const toggleSourceState = () => {
    alertDialog(
      "Switch Source?",
      "You are about to switch your data source. Continue?",
      () => {
        const source = appSource;
        if (source === appSources.live)
          localStorage.setItem("app-source", "demo");
        else localStorage.setItem("app-source", "live");

        toastMsg("switching...", "info");
        window.location.reload();
      }
    );
  };

  return (
    <div onClick={toggleSourceState}>
      {appSource === appSources.live ? (
        <Chip label="Live" color="success" />
      ) : (
        <Chip label="Demo" color="warning" />
      )}
    </div>
  );
};

const AppVersion = () => {
  return <span>{process.env.REACT_APP_APP_VERSION}</span>;
};

export {
  RequiredIndicator,
  SectionHeader,
  PageHeader,
  SaveButton,
  SearchInput,
  DetailTile,
  Copyright,
  AppVersion,
  LiveDemoComponent,
};
