import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { isAuthenticated } from "../helpers/auth";
import { appLinks } from "../helpers/config";
import { PrimaryNav } from "../components/navigation";

export default function AppRoot() {
  const nav = useNavigate();

  useEffect(() => {
    !isAuthenticated() && nav(appLinks.login, { replace: false });
  }, []);

  return (
    <>
      {isAuthenticated() && (
        <div className="app-layout">
          <PrimaryNav />
          <div className="layout-outlet">
            <div className="row">
              <div className="mx-auto col-md-10 col-12">
                <div className="container">
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
