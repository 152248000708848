import { SectionHeader } from "../../components/shared";
import { useState } from "react";
import { Center, SegmentedControl } from "@mantine/core";
import {
  IconHospital,
  IconNurse,
  IconCash,
  IconReportAnalytics,
  IconOutbound,
  IconCalendar,
} from "@tabler/icons-react";
import { FacilitiesPage } from "./facilities";
import { ConsultationsPage } from "./consultations";
import { PartnerActivityLogs } from "./logs";
import { ExpensesPage } from "./expenses";
import { AppointmentsPage } from "./appointments";

const CoreIndex = () => {
  const [tab, setTab] = useState("facilities");

  return (
    <section className="mt-3">
      <SectionHeader title="Core" description="Manage partner facilities" />
      <SegmentedControl
        className="mb-2"
        size="md"
        value={tab}
        onChange={setTab}
        data={[
          {
            label: (
              <Center>
                <IconHospital color="teal" className="me-2" />
                <span className="d-none d-md-inline">Facilities</span>
              </Center>
            ),
            value: "facilities",
          },
          {
            value: "consultations",
            label: (
              <Center>
                <IconNurse color="teal" className="me-2" />
                <span className="d-none d-md-inline">Consultations</span>
              </Center>
            ),
          },
          {
            value: "appointments",
            label: (
              <Center>
                <IconCalendar color="teal" className="me-2" />
                <span className="d-none d-md-inline">Appointments</span>
              </Center>
            ),
          },
          // {
          //   value: "revenue",
          //   label: (
          //     <Center>
          //       <IconCash color="teal" className="me-2" />
          //       <span className="d-none d-md-inline">Revenue</span>
          //     </Center>
          //   ),
          // },
          {
            value: "expenses",
            label: (
              <Center>
                <IconOutbound color="teal" className="me-2" />
                <span className="d-none d-md-inline">Expenses</span>
              </Center>
            ),
          },
          {
            value: "logs",
            label: (
              <Center>
                <IconReportAnalytics color="teal" className="me-2" />
                <span className="d-none d-md-inline">Logs</span>
              </Center>
            ),
          },
        ]}
      />
      {tab === "facilities" && <FacilitiesPage key="facilities" />}
      {tab === "consultations" && <ConsultationsPage key="consultations" />}
      {tab === "appointments" && <AppointmentsPage key="appointments" />}
      {tab === "logs" && <PartnerActivityLogs key="logs" />}
      {tab === "expenses" && <ExpensesPage key="expenses" />}
    </section>
  );
};

export { CoreIndex };
