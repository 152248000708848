import { Box, Menu, Paper } from "@mantine/core";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import EditClinicForm from "./components/edit-clinic";
import { useQuery } from "@tanstack/react-query";
import { ModeStateProp } from "../../helpers/props";
import { getAllExpenses } from "../../helpers/api";
import { Chip } from "@mui/material";
import _ from "lodash";
import { SearchInput } from "../../components/shared";
import { IconCategory2 } from "@tabler/icons-react";
import FiltersBoard from "./components/filters-board";
import { IExpense, IFacility } from "../../helpers/interfaces";
import { cedisLocale } from "../../helpers/utilities";

const ExpensesPage = () => {
  const [filteredData, setFilteredData] = useState([]);
  const [mode, setMode] = useState<Partial<ModeStateProp>>();
  const [showFilters, setFilters] = useState<boolean>();

  let totalExpenses =
    filteredData.length > 0
      ? filteredData
          .map((fd: any) => fd.amount)
          .reduce(
            (prev: any = 0, cur: any, index: number, arr: any[]): any =>
              parseFloat(prev) + parseFloat(cur)
          )
      : 0;

  // queries
  const {
    data: expenses = [],
    isFetching,
    refetch,
  } = useQuery({
    queryFn: () =>
      getAllExpenses().then((data) => {
        if (data) {
          setFilteredData(data);
          return data;
        }
      }),
    queryKey: ["all-expenses"],
  });

  const handleOpen = (id: string) => {
    setMode({ edit: true, id });
  };

  return (
    <section className="pb-4">
      {mode?.edit ? (
        <Paper>
          <EditClinicForm
            canEdit={true}
            id={mode?.id}
            onUpdate={refetch}
            onClose={() => setMode({ edit: false, id: "" })}
          />
        </Paper>
      ) : (
        <>
          <div className="row my-3">
            <div className="col-12 col-md-6">
              <div className="d-flex">
                <SearchInput
                  onChange={(v) => {
                    setFilteredData(
                      expenses?.filter(
                        (exp: IExpense) =>
                          exp?.description
                            .toString()
                            .toLowerCase()
                            .includes(v) ||
                          exp?.amount.toString().toLowerCase().includes(v) ||
                          exp?.date.toString().toLowerCase().includes(v) ||
                          exp?.category.title.toLowerCase().includes(v) ||
                          exp?.branch?.branchName?.toLowerCase().includes(v) ||
                          exp?.branch?.facility.facilityName
                            ?.toLowerCase()
                            .includes(v)
                      )
                    );
                  }}
                  placeholder="search expenses"
                />
              </div>
            </div>
            <div className="col-12 col-md-6 d-flex justify-content-end align-items-center">
              <div>
                <span className="text-black ms-2">Total Expenses (GHS)</span>
                <Chip
                  className="ms-2"
                  label={cedisLocale.format(totalExpenses)}
                />
              </div>
              <span className="mx-2 text-secondary">|</span>
              <div>
                <Chip label={filteredData?.length || 0} />
                <span className="text-black ms-2">Records</span>
              </div>
              <button
                onClick={() => setFilters(!showFilters)}
                className={`ms-2 button is-light ${showFilters && " btn-prim"}`}
              >
                <IconCategory2 />
              </button>
            </div>
          </div>
          {showFilters && (
            <div className="py-3">
              <FiltersBoard />
            </div>
          )}
          <Paper>
            <Box style={{ height: 500, width: "100%" }}>
              <DataGrid
                rows={filteredData}
                columns={[
                  {
                    field: "id",
                    headerName: "#",
                    sortable: true,
                    width: 50,
                    renderCell: (params: any) =>
                      params.api.getRowIndexRelativeToVisibleRows(
                        params.row.id
                      ) + 1,
                  },
                  {
                    field: "branch",
                    headerName: "Facility Name",
                    sortable: true,
                    width: 200,
                    renderCell: (params) =>
                      `${params.value?.facility?.facilityName} (${params.value?.branchName})`,
                  },
                  {
                    field: "date",
                    headerName: "Date",
                    sortable: true,
                    width: 150,
                    renderCell: (params) =>
                      new Date(params.value).toLocaleDateString(),
                  },
                  {
                    field: "description",
                    headerName: "Description",
                    sortable: true,
                    width: 250,
                  },
                  {
                    field: "amount",
                    headerName: "Amount",
                    sortable: true,
                    width: 120,
                    valueFormatter: (value: any) => cedisLocale.format(value),
                  },
                  {
                    field: "",
                    headerName: "Category",
                    sortable: true,
                    flex: 1,
                    renderCell: (params: any) => params?.row?.category?.title,
                  },
                  // {
                  //   field: "#",
                  //   headerName: "",
                  //   width: 100,
                  //   renderCell: (row: any) => {
                  //     return (
                  //       <button
                  //         className="button is-ghost mt-1"
                  //         onClick={() => handleOpen(row.id)}
                  //       >
                  //         <span className="bi bi-eye me-2" />
                  //         view
                  //       </button>
                  //     );
                  //   },
                  // },
                ]}
                loading={isFetching}
              />
            </Box>
          </Paper>
        </>
      )}
    </section>
  );
};

export { ExpensesPage };
