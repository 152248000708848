import * as XLSX from "xlsx";
import { differenceInDays, isPast } from "date-fns";
import _ from "lodash";
import { getRole } from "./auth";
import { rbac, ROLES, resources } from './rbac'
import validator from "validator";
import { toast } from "react-toastify";
import { ToastIcon } from "react-toastify/dist/types";
import { confirmAlert } from "react-confirm-alert";
const CryptoJS = require("crypto-js");



/**
 * Converts all the first alphabetic characters in a string to uppercase.
 * @param {string} word word or sentence you wanna manipulate
 * @returns {string} word
 */
export const toTitleCase = (word: string) => {
	let firstChar = word?.slice(0, 1),
		remainder = word?.slice(1);
	return `${firstChar.toUpperCase()}${remainder.toLowerCase()}`;
};

export const cedisLocale = Intl.NumberFormat("en-GH");


/**
 * calculates price change % without the percentage sign
 * @param {number} oldPrice the old or previous price which has been changed
 * @param {number} newPrice the new price 
 * @returns {number}
 */
export const priceChangePercentage = (oldPrice: number, newPrice: number) => {
	if (oldPrice === null || undefined) return;
	if (newPrice === null || undefined) return;

	const change = oldPrice - newPrice;
	if (change <= 0) return 0;

	const percentage = change / oldPrice;
	return (percentage * 100).toFixed(0);
};


/**
 * generates a random number between a specified start and end parameters
 * @param {number} min minimum or starting number
 * @param {number} max maximum or closing/end number
 * @returns {number} random number
 */
export function getRandomNumberBetween(min: number, max: number) {
	return Math.floor(Math.random() * (max - min + 1) + min);
}

/**
 * encrypts a string using the provided key
 * @param {string} string the string to encrypt
 * @param {string} key the encryption key to use
 * @returns {string} the encrypted string
 */
export const encryptString = (string: string, key: string) => {
	return CryptoJS.AES.encrypt(string, key).toString();
};


/**
 * decrypts an encrypted string using the provided key
 * @param {string} string the encrypted string to decrypt
 * @param {string} key the decryption key
 * @returns {string} the decrypted string
 */
export const decryptString = (string: string, key: string) => {
	const decrypted = CryptoJS.AES.decrypt(string, key);
	return decrypted.toString(CryptoJS.enc.Utf8);
};


/**
 * converts the given string into a valid url
 * @param {string} text the string to convert into a url
 * @returns {string} the url
 */
export const toUrl = (text: string) => {
	if (text === undefined || text.length === 0 || text === "") return;
	return text.trim().toLowerCase().replace(" ", "-");
};



/**
 * 
 * @param message the toast or alert message
 * @param type the type of message theme to use
 * @param icon any associate icons to apply
 * @param position where to place the toast component on screen
 * @param options additional options to customize message
 * @returns 
 */
export const toastMsg = (
	message: string,
	type: 'default' | 'success' | 'warning' | 'error' | 'info' = 'default',
	icon?: ToastIcon | undefined,
	position: 'bottom-right' | 'bottom-center' | 'bottom-left' | 'top-right' | 'top-left' | 'top-center' = "top-center",
	options?: {}
) => {
	return toast(message, {
		icon,
		position,
		type,
		hideProgressBar: true,
		...options
	})
}



/**
 * Shows a dialog box and awaits user's click response
 * @param title alert dialog title
 * @param message dialog message for user to act on
 * @param onClick action to perform when yes is clicked 
 * @param noClick action to take when no is clicked
 * @param yesLabel optional label for yes button
 * @param noLabel optional label for no button
 * @returns 
 */
export const alertDialog = (
	title: string,
	message: string,
	onClick: () => void,
	noClick?: () => void,
	yesLabel: string = "Yes",
	noLabel: string = "No"
) => {
	return confirmAlert({
		title,
		message,
		buttons: [
			{
				label: yesLabel,
				className: "bg-success",
				onClick
			},
			{
				label: noLabel,
				className: "bg-secondary",
				onClick: noClick
			},
		],
	});
}


/**
 * returns a specific background color based on the calculated percentage complete
 * @param {number} completedTasks 
 * @param {number} totalTasks 
 * @returns {string}
 */
export const consultationColorCoding = (completedTasks: number, totalTasks: number) => {
	const percentage = parseInt((((completedTasks || 0) / (totalTasks || 0)) * 100).toFixed(2));
	const colorCoding = `
            ${percentage <= 10 && 'bg-danger'}
            ${(percentage > 10 && percentage <= 49) && 'bg-warning'}
            ${(percentage > 49 && percentage <= 89) && 'bg-info'}
            ${(percentage > 89) && 'bg-success'}
            `;

	return colorCoding;
};


/**
 * takes two numbers and returns the percentage difference between them
 * @param {number} left first number
 * @param {number} right second number
 * @returns number
 */
export const percentage = (left: number, right: number) => {
	if (left === 0 && right === 0)
		return 0;

	return ((left / right) * 100);
	// return percentage;
};


// /**
//  * exports a given table's data to spreadsheet document
//  * @param {string} tableId DOM id of the table element
//  * @param {string} fileName the spreadsheet filename when generate
//  * @param {string} sheetName the sheet name for the document
//  * @returns any
//  */
// export const exportToExcel = (
// 	tableId: string,
// 	fileName = "Rivia",
// 	sheetName = "sheet1"
// ) => {
// 	try {
// 		var elt = document.getElementById(tableId);
// 		var wb = XLSX.utils.table_to_book(elt, { sheet: sheetName });
// 		return XLSX.writeFile(wb, `${fileName}.xlsx`);
// 	} catch (ex) {
// 		alert('error exporting...')
// 		// openNotification(
// 		// 	"Export Error",
// 		// 	"there was an error exporting to excel",
// 		// 	"error"
// 		// );
// 	}
// };


/**
 * checks if the app is running as an electron package
 * @returns {Boolean}
 */
export const isElectron = () => {
	const userAgent = navigator.userAgent.toLowerCase();
	return userAgent.indexOf(" electron/") !== -1;
};


/**
 * returns a url formatted string from an array of strings
 * @param {string[]} paths list of strings to convert into a url
 * @return {string}
 */
export const generateRoute = (paths: string[]) => {
	let path = "";
	paths.map((p) => {
		path += "/" + p.trim().replace("/", "");
	});

	return path;
};




/**
 * Returns a numeric value denoting the password strength
 * @param {string} password the password string
 * @returns {number}
 */
export const passwordStrength = (password: string) => {
	return validator.isStrongPassword(password, {
		minLength: 6,
		minLowercase: 1,
		minSymbols: 1,
		minUppercase: 1,
		pointsForContainingLower: 5,
		pointsForContainingSymbol: 10,
		pointsForContainingUpper: 5,
		pointsForContainingNumber: 5,
		pointsPerUnique: 5,
		// pointsPerRepeat: 0,
		returnScore: true
	});
};


// /**
//  * check if a user has the appropriate permission to perform an action on a given resource
//  * @param {string} resouce application resource to check permission against
//  * @param {string} action a specified action that a given user can perform e.g. edit
//  * @returns {Boolean}
//  */
// export const hasPermission = (resouce: string, action: string) => {
// 	try {
// 		if (rbac[ROLES[getRole()]][resources[resouce]][action])
// 			return true;
// 		else
// 			return false;
// 	} catch (error) {
// 		return false;
// 	}
// }


/**
 * censors (masks) an email an address
 * @param {string} email email address to mask
 * @returns {Array}
 */
export const maskEmail = (email: string) => {
	if (validator.isEmail(email)) {
		const esplit = email.trim().toLowerCase().split("@")
		const mask = esplit[0].length > 3 ?
			`${esplit[0].substring(0, 2)}****${esplit[0].substring(esplit[0].length - 1)}` :
			`${esplit[0].substring(0, 2)}****`

		return `${mask}@${esplit[1]}`
	}

	return null
}



// /**
//  * returns details of a currency based on the provided code
//  * @param {string} code currency code to search for
//  */
// export const findCurrency = (code: string) => {
// 	return currencies.find((cur: string) => cur.code == code)
// }


// /**
//  * returns details of a country based on the provided code
//  * @param {string} code country code to search for
//  */
// export const findCountry = (code: string) => {
// 	return countries.find(coun => coun.code === code)
// }
