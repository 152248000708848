import { createBrowserRouter } from "react-router-dom";
import AppRoot from "./layout";
import ErrorPage from "../error-page";
import {
  ChangePassword,
  CoreIndex,
  ExpressIndex,
  HomeIndex,
  IncomingPanelPage,
  LoginPage,
  ReportsIndex,
  ResetPassword,
  SetupIndex,
} from "../pages";
import { appLinks } from "../helpers/config";
import { generateRoute } from "../helpers/utilities";

const routes = createBrowserRouter([
  {
    path: appLinks.login,
    element: <LoginPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: appLinks.resetpassword,
    element: <ResetPassword />,
    errorElement: <ErrorPage />,
  },
  // {
  //   path: appLinks.changePassword,
  //   element: <ChangePassword />,
  //   errorElement: <ErrorPage />,
  // },
  {
    path: "/",
    element: <AppRoot />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <HomeIndex />,
        errorElement: <ErrorPage />,
      },
      {
        path: appLinks.core.index,
        element: <CoreIndex />,
      },
      {
        path: appLinks.express.index,
        element: <ExpressIndex />,
      },
      {
        path: appLinks.reports.index,
        element: <ReportsIndex />,
      },
      {
        path: appLinks.controlPanel.index,
        element: <SetupIndex />,
      },
      {
        path: generateRoute([
          appLinks.controlPanel.index,
          appLinks.controlPanel.incoming,
        ]),
        element: <IncomingPanelPage />,
      },
    ],
  },
]);

export default routes;
