import { Loader } from "@mantine/core";
import _ from "lodash";
import { EditFormProp } from "../../../helpers/props";

export default function EditContactUsComponent({
  id,
  onUpdate,
}: Partial<EditFormProp>) {
  return <h3>Edit / Open Message component</h3>;
}
