import { Box, Paper } from "@mantine/core";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import EditClinicForm from "./components/edit-clinic";
import { useQuery } from "@tanstack/react-query";
import { ModeStateProp } from "../../helpers/props";
import { getAllAppointments } from "../../helpers/api";
import { consultationColorCoding, percentage } from "../../helpers/utilities";
import { Chip } from "@mui/material";
import _ from "lodash";
import { SearchInput } from "../../components/shared";
import { IconCategory2 } from "@tabler/icons-react";
import FiltersBoard from "./components/filters-board";
import { IAppointment } from "../../helpers/interfaces";

const AppointmentsPage = () => {
  const [filteredData, setFilteredData] = useState([]);
  const [mode, setMode] = useState<Partial<ModeStateProp>>();
  const [showFilters, setFilters] = useState<boolean>();

  // queries
  const {
    data: appointments = [],
    isFetching,
    refetch,
  } = useQuery({
    queryFn: () =>
      getAllAppointments().then((data) => {
        if (data) {
          setFilteredData(data);
          return data;
        }
      }),
    queryKey: ["all-appointments"],
  });

  const handleOpen = (id: string) => {
    setMode({ edit: true, id });
  };

  return (
    <section className="pb-4">
      {mode?.edit ? (
        <Paper>
          <EditClinicForm
            canEdit={true}
            id={mode?.id}
            onUpdate={refetch}
            onClose={() => setMode({ edit: false, id: "" })}
          />
        </Paper>
      ) : (
        <>
          <div className="row my-3">
            <div className="col-12 col-md-9">
              <div className="d-flex">
                <SearchInput
                  onChange={(v) => {
                    setFilteredData(
                      appointments?.filter(
                        (appt: IAppointment) =>
                          appt?.fullName.toLowerCase().includes(v) ||
                          appt?.contact.toLowerCase().includes(v) ||
                          appt?.appointmentType?.toLowerCase().includes(v) ||
                          appt?.source?.toLowerCase().includes(v) ||
                          appt?.startDate
                            ?.toString()
                            .toLowerCase()
                            .includes(v) ||
                          appt?.startTime?.toString().toLowerCase().includes(v)
                      )
                    );
                  }}
                  placeholder="search appointments"
                />
              </div>
            </div>
            <div className="col-12 col-md-3 d-flex justify-content-end align-items-center">
              <div>
                <Chip className="ms-2" label={filteredData?.length || 0} />
                <span className="text-black ms-2">Appointments</span>
              </div>
              <button
                onClick={() => setFilters(!showFilters)}
                className={`ms-2 button is-light ${showFilters && " btn-prim"}`}
              >
                <IconCategory2 />
              </button>
            </div>
          </div>
          {showFilters && (
            <div className="py-3">
              <FiltersBoard />
            </div>
          )}
          <Paper>
            <Box style={{ height: 500, width: "100%" }}>
              <DataGrid
                rows={filteredData}
                columns={[
                  {
                    field: "id",
                    headerName: "#",
                    sortable: true,
                    width: 50,
                    renderCell: (params: any) =>
                      params.api.getRowIndexRelativeToVisibleRows(
                        params.row.id
                      ) + 1,
                  },
                  {
                    field: "createdAt",
                    headerName: "Created Date",
                    sortable: true,
                    width: 150,
                    renderCell: (params) =>
                      new Date(params.value).toDateString(),
                  },
                  {
                    field: "fullName",
                    headerName: "Client",
                    sortable: true,
                    width: 170,
                    renderCell: (params: any) =>
                      params?.row?.fullName
                        ? params?.row?.fullName
                        : `${params.row?.patient?.firstName} ${params.row?.patient?.lastName}`,
                  },
                  {
                    field: "branch",
                    headerName: "Facility",
                    sortable: true,
                    width: 180,
                    renderCell: (params) =>
                      `${params.value?.facility?.facilityName}`,
                  },
                  {
                    field: "appointmentType",
                    headerName: "Type",
                    sortable: true,
                    width: 100,
                    valueFormatter: (value: any) => _.capitalize(value),
                  },
                  {
                    field: "source",
                    headerName: "Source",
                    sortable: true,
                    // flex: 1,
                    width: 120,
                    valueFormatter: (value: any) =>
                      value === "internal" ? "RiviaOS" : _.capitalize(value),
                  },
                  {
                    field: "isComplete",
                    headerName: "Status",
                    sortable: true,
                    width: 120,
                    renderCell: (row: any) =>
                      row.isComplete ? (
                        <Chip label="Complete" color="info" />
                      ) : (
                        <Chip label="Pending" color="warning" />
                      ),
                  },
                  {
                    field: "#",
                    headerName: "",
                    width: 100,
                    renderCell: (row: any) => {
                      return (
                        <button
                          className="button is-ghost mt-1"
                          // onClick={() => handleOpen(row.id)}
                        >
                          <span className="bi bi-eye me-2" />
                          view
                        </button>
                      );
                    },
                  },
                ]}
                loading={isFetching}
              />
            </Box>
          </Paper>
        </>
      )}
    </section>
  );
};

export { AppointmentsPage };
