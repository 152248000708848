import { RouterProvider } from "react-router-dom";
import routes from "./routes/root";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { MantineProvider } from "@mantine/core";

import "react-confirm-alert/src/react-confirm-alert.css";
import "react-toastify/dist/ReactToastify.css";
import "@mantine/core/styles.css";
import "bulma/css/bulma.css";
import "./static/css/styles.css";
import "bootstrap-icons/font/bootstrap-icons.min.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { ToastContainer } from "react-toastify";

const queryClient = new QueryClient();

function App() {
  // todo: logout user when tab is closed
  return (
    <div className="App">
      <MantineProvider>
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={routes} />
          <ReactQueryDevtools />
        </QueryClientProvider>
        <ToastContainer />
      </MantineProvider>
    </div>
  );
}

export default App;
