import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { authenticate } from "../../helpers/auth";
import PageTitle from "../../components/page-title";
import { appLinks } from "../../helpers/config";
import { Copyright } from "../../components/shared";
import { postLogin } from "../../helpers/api";
import { Alert, Divider } from "@mantine/core";
import { useMutation } from "@tanstack/react-query";
import { IconX } from "@tabler/icons-react";
import logo from "../../static/img/rivia-icon.png";
import { toastMsg } from "../../helpers/utilities";
import _ from "lodash";
import { ILogin } from "../../helpers/interfaces";

const LoginPage = () => {
  const { register, handleSubmit } = useForm();
  const [show, setShow] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const navigate = useNavigate();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (data: any) => postLogin(data as ILogin),
    mutationKey: ["login"],
    onSuccess: (data: any, variables: any, context: any) => {
      if (data.status === 201) {
        authenticate(data.data.token, data.data.data);
        navigate(appLinks.home, { replace: true });
        return;
      }

      throw data;
    },

    onError: (error: any, variables: any, context: any) => {
      const err = error?.response?.data?.message || error?.message || error;

      if (_.isArray(err)) {
        err.map((err) => toastMsg(err.message, "error"));
        setErrMsg("login error");
      } else {
        toastMsg(err, "error");
        setErrMsg(err);
      }
    },
  });

  return (
    <div
      style={{
        height: "100vh",
      }}
    >
      <PageTitle title="Login" />
      <div className="row">
        <div className="mx-auto col-md-4 col-11">
          <div className="">
            <div className="text-center">
              <div className="mt-5">
                <h2>Rivia Admin</h2>
                {/* <img src={logo} width={200} alt="Rivia Admin logo" /> */}
              </div>
              <h5>Login</h5>
            </div>
            {errMsg && (
              <Alert
                icon={<IconX />}
                variant="filled"
                color="red"
                className="text-center mt-3"
              >
                {errMsg}
              </Alert>
            )}
            <form onSubmit={handleSubmit((data) => mutateAsync(data))}>
              <div className="row">
                <div className="field">
                  <label className="mb-0" htmlFor="email">
                    Email
                  </label>
                  <p className="mb-1 control has-icons-left">
                    <input
                      className="input"
                      autoFocus
                      autoComplete="true"
                      type="email"
                      {...register("email", { required: true })}
                      id="email"
                      placeholder="email"
                    />
                    <span className="icon is-small is-left">
                      <i className="bi bi-person"></i>
                    </span>
                  </p>
                </div>
                <div className="field">
                  <label className="mb-0" htmlFor="password">
                    Password
                  </label>
                  <p className="control has-icons-left">
                    <input
                      className="input"
                      id="password"
                      autoComplete="true"
                      type={`${!show ? "password" : "text"}`}
                      {...register("password", { required: true })}
                      placeholder="password"
                    />
                    <span className="icon is-small is-left ">
                      <i className="bi bi-lock hover-hand"></i>
                    </span>
                    <small
                      className="text-secondary hover-hand"
                      onClick={() => setShow(!show)}
                    >
                      {`${!show ? "show " : "hide "} password`}
                    </small>
                  </p>
                </div>
                <div>
                  <button
                    className={`bokx-btn btn-prim w-100 ${
                      isPending && " button is-loading"
                    }`}
                  >
                    Login
                  </button>
                </div>
              </div>
            </form>
            <div className="px-3 mt-3 row">
              <Link to={appLinks.resetpassword}>Reset Password</Link>
              <Divider className="my-2" />
              {/* <p className="mb-1">
                Having challenges?
                <Link to="/"> Get help</Link>
              </p> */}
              <small>
                <Copyright />
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { LoginPage };
