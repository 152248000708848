import { Box, Menu, Paper } from "@mantine/core";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import EditClinicForm from "./components/edit-clinic";
import NewFacilityForm from "./components/new-facility";
import { useQuery } from "@tanstack/react-query";
import { ModeStateProp } from "../../helpers/props";
import { deleteFacility, getAllFacilities } from "../../helpers/api";
import { alertDialog, toastMsg } from "../../helpers/utilities";
import { Chip } from "@mui/material";
import _ from "lodash";
import { SearchInput } from "../../components/shared";
import { IconCategory2 } from "@tabler/icons-react";
import FiltersBoard from "./components/filters-board";
import { IFacility } from "../../helpers/interfaces";

const FacilitiesPage = () => {
  const [filteredData, setFilteredData] = useState([]);
  const [mode, setMode] = useState<Partial<ModeStateProp>>();
  const [showFilters, setFilters] = useState<boolean>();

  // queries
  const {
    data: facilities = [],
    isFetching,
    refetch,
  } = useQuery({
    queryFn: () =>
      getAllFacilities().then((data) => {
        if (data) {
          setFilteredData(data);
          return data;
        }
      }),
    queryKey: ["all-facilities"],
  });

  const handleNew = () => {
    setMode({ new: true });
  };

  const handleOpen = (id: string) => {
    setMode({ edit: true, id });
  };

  const handleDelete = (id: string) => {
    alertDialog(
      "Suspend Facility",
      "This will prevent staff members from accessing RiviaOS. Continue?",
      () =>
        deleteFacility(id)
          .then(() => {
            toastMsg("Facility suspended!", "success");
            refetch();
          })
          .catch(() => toastMsg("Error processing request", "error"))
    );
  };

  return (
    <section className="pb-4">
      {mode?.new ? (
        <Paper>
          <NewFacilityForm
            onClose={() => setMode({ edit: false, id: "" })}
            onSuccess={refetch}
          />
        </Paper>
      ) : mode?.edit ? (
        <Paper>
          <EditClinicForm
            canEdit={true}
            id={mode?.id}
            onUpdate={refetch}
            onClose={() => setMode({ edit: false, id: "" })}
          />
        </Paper>
      ) : (
        <>
          <div className="row my-3">
            <div className="col-12 col-md-9">
              <div className="d-flex">
                <button
                  onClick={handleNew}
                  className="bokx-btn btn-prim is-small py-1 px-3 me-2"
                >
                  <span className="bi bi-plus-circle"></span>
                  <span className="d-none d-md-inline ms-2">Onboard</span>
                </button>
                <SearchInput
                  onChange={(v) => {
                    setFilteredData(
                      facilities.filter(
                        (fac: IFacility) =>
                          fac.facilityName.toLowerCase().includes(v) ||
                          fac.contact.toLowerCase().includes(v) ||
                          fac.type.toLowerCase().includes(v)
                      )
                    );
                  }}
                  placeholder="search facilities"
                />
              </div>
            </div>
            <div className="col-12 col-md-3 d-flex justify-content-end align-items-center">
              <div>
                <Chip className="ms-2" label={filteredData?.length || 0} />
                <span className="text-black ms-2">Facilities</span>
              </div>
              <button
                onClick={() => setFilters(!showFilters)}
                className={`ms-2 button is-light ${showFilters && " btn-prim"}`}
              >
                <IconCategory2 />
              </button>
            </div>
          </div>
          {showFilters && (
            <div className="py-3">
              <FiltersBoard />
            </div>
          )}
          <Paper>
            <Box style={{ height: 500, width: "100%" }}>
              <DataGrid
                rows={filteredData}
                columns={[
                  {
                    field: "id",
                    headerName: "#",
                    sortable: true,
                    width: 50,
                    renderCell: (params: any) =>
                      params.api.getRowIndexRelativeToVisibleRows(
                        params.row.id
                      ) + 1,
                  },
                  {
                    field: "facilityName",
                    headerName: "Facility Name",
                    sortable: true,
                    width: 200,
                  },
                  {
                    field: "branches",
                    headerName: "Outlets",
                    sortable: true,
                    width: 80,
                    renderCell: (params: any) =>
                      params.row.branches?.length || 0,
                  },
                  {
                    field: "contact",
                    headerName: "Phone",
                    sortable: true,
                    width: 120,
                  },
                  {
                    field: "type",
                    headerName: "Type",
                    sortable: true,
                    width: 120,
                    valueFormatter: (value: any) => _.capitalize(value),
                  },
                  {
                    field: "isDeleted",
                    headerName: "Status",
                    width: 100,
                    sortable: true,
                    renderCell: (row: any) =>
                      row.isDeleted === true ? (
                        <Chip label="Suspended" color="error" size="small" />
                      ) : (
                        <Chip label="Active" color="info" size="small" />
                      ),
                  },
                  {
                    field: "createdAt",
                    headerName: "Date Onboarded",
                    sortable: true,
                    flex: 1,
                    valueFormatter: (value: any) =>
                      new Date(value).toUTCString(),
                  },

                  {
                    field: "#",
                    headerName: "-",
                    width: 50,
                    renderCell: (row: any) => {
                      return (
                        <div className="d-flex mt-2">
                          <Menu>
                            <Menu.Target>
                              <button className="button is-small is-ghost px-2">
                                <span className="bi bi-list me-2"></span>
                                {/* Actions */}
                              </button>
                            </Menu.Target>

                            <Menu.Dropdown>
                              <Menu.Item
                                color="blue"
                                // onClick={() => handleOpen(row.id)}
                              >
                                <span className="bi bi-arrow-up-right-square me-2" />
                                Open
                              </Menu.Item>
                              <Menu.Item
                                color="red"
                                // onClick={() => handleDelete(row.id)}
                              >
                                <span className="bi bi-stop-circle me-2" />
                                Suspend
                              </Menu.Item>
                            </Menu.Dropdown>
                          </Menu>
                        </div>
                      );
                    },
                  },
                ]}
                loading={isFetching}
              />
            </Box>
          </Paper>
        </>
      )}
    </section>
  );
};

export { FacilitiesPage };
