import _ from "lodash";
import { useMutation } from "@tanstack/react-query";
import { RequiredIndicator } from "../../../components/shared";
import { Divider } from "@mantine/core";

import { EditFormProp } from "../../../helpers/props";
import { alertDialog, toastMsg } from "../../../helpers/utilities";
import { postFacility } from "../../../helpers/api";
import { useState } from "react";
import { IOnboardFacility, IOnboardStaff } from "../../../helpers/interfaces";
import {
  onboardingFacilitySchema,
  onboardingStaffSchema,
} from "../../../helpers/schemas";

const NewFacilityForm = ({
  showFooter = false,
  onSuccess,
  showHeader = true,
  onClose,
}: Partial<EditFormProp>) => {
  const [facility, setFac] = useState<IOnboardFacility | null>();
  const [staff, setStaff] = useState<IOnboardStaff | null>();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (data) => postFacility(data),
    onSuccess: (data: any, variables: any, context: any) => {
      if (data.status === 201) {
        toastMsg(data.data.message, "success");
        onSuccess!();
        return;
      }

      throw data;
    },

    onError: (error: any, variables: any, context: any) => {
      const err =
        error?.response?.data?.message ||
        error?.message ||
        error ||
        "error processing request!";

      if (_.isArray(err)) {
        err.map((err) => toastMsg(err.message, "error"));
      } else {
        toastMsg(err, "error");
      }
    },
    retry: true,
  });

  const handleOnboard = (fac: IOnboardFacility, staff: IOnboardStaff) => {
    alertDialog(
      "Onboard Facility",
      "Add a facility to the Rivia records?",
      () => {
        //validate staffs & facilities
        onboardingFacilitySchema
          .validate(fac, { abortEarly: true, stripUnknown: true })
          .then((validFacs) => {
            onboardingStaffSchema
              .validate(staff, {
                abortEarly: true,
                stripUnknown: true,
              })
              .then((validStaffs) => {
                const cleanedData = { facility: validFacs, staff: validStaffs };
                mutateAsync(cleanedData);
              })
              .catch((err) => {
                if (_.isArray(err.errors)) {
                  toastMsg(err.errors[0], "error");
                } else {
                  toastMsg(err, "error");
                }
              });
          })
          .catch((err) => {
            if (_.isArray(err.errors)) {
              toastMsg(err.errors[0], "error");
            } else {
              toastMsg(err, "error");
            }
          });
      }
    );
  };

  return (
    <div className="p-3">
      {showHeader && (
        <div className="d-flex justify-content-between align-items-center sticky-top bg-white pt-1 px-3">
          <div>
            <h5>Add Facility</h5>
          </div>
          <div className="buttons has-addons">
            <button
              onClick={() => handleOnboard(facility!, staff!)}
              className={`button btn-prim ${isPending && " is-loading"}`}
            >
              <span className="bi bi-save me-2"></span>
              <span className="d-none d-md-inline">Save</span>
            </button>
            <button className="button bokx-btn" onClick={onClose}>
              <span className="bi bi-x-circle me-2"></span>
              <span className="d-none d-md-inline">Close</span>
            </button>
          </div>
        </div>
      )}
      <div className="p-3">
        <Divider label="Facility" />
        <div className="row mt-3">
          <div className="col-md-6 col-12">
            <label htmlFor="facilityName">
              Facility Name
              <RequiredIndicator />
            </label>
            <input
              type="text"
              id="facilityName"
              autoFocus
              maxLength={250}
              value={facility?.facilityName}
              onChange={(e) =>
                setFac({
                  ...facility!,
                  facilityName: e.target.value,
                })
              }
              className="input"
              placeholder="official business name of facility"
            />
          </div>
          <div className="col-md-6 col-12 mt-3 mt-md-0">
            <label htmlFor="facilityType">
              Type
              <RequiredIndicator />
            </label>
            <select
              id="facilityType"
              className="d-block w-100 input"
              value={facility?.type}
              onChange={(e) =>
                setFac({
                  ...facility!,
                  type: e.target.value,
                })
              }
            >
              <option value="" selected disabled>
                select
              </option>
              <option value={"clinic"} id="facilityType">
                Clinic
              </option>
              <option value={"laboratory"} id="laboratory">
                Diagnostic Center (Lab)
              </option>
              <option value={"pharmacy"} id="pharmacy">
                Pharmacy
              </option>
            </select>
          </div>
        </div>

        <div className="row my-3">
          <div className="col-md-6 col-12">
            <label htmlFor="contact">
              Contact
              <RequiredIndicator />
            </label>
            <input
              type="tel"
              id="contact"
              className="input"
              placeholder="contact number"
              value={facility?.contact}
              onChange={(e) =>
                setFac({
                  ...facility!,
                  contact: e.target.value,
                })
              }
            />
          </div>
          <div className="col-md-6 col-12 mt-3 mt-md-0">
            <label htmlFor="email">
              Email
              <small className="ms-2">
                (admin user account details will be sent here)
              </small>
              <RequiredIndicator />
            </label>
            <input
              type="email"
              id="email"
              className="input"
              placeholder="active email address"
              value={facility?.email}
              onChange={(e) =>
                setFac({
                  ...facility!,
                  email: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div>
          <label htmlFor="location">Location</label>
          <input
            type="text"
            step="0.01"
            min="0"
            id="location"
            className="input"
            placeholder="physical location of facility"
            value={facility?.location}
            onChange={(e) =>
              setFac({
                ...facility!,
                location: e.target.value,
              })
            }
          />
        </div>
        <Divider className="my-4" label="Contact Person" />
        <div className="row my-3">
          <div className="col-md-6 col-12">
            <label htmlFor="firstName">
              First Name
              <RequiredIndicator />
            </label>
            <input
              type="text"
              id="firstName"
              className="input"
              placeholder="first name"
              value={staff?.firstName}
              onChange={(e) =>
                setStaff({
                  ...staff!,
                  firstName: e.target.value,
                })
              }
            />
          </div>
          <div className="col-md-6 col-12 mt-3 mt-md-0">
            <label htmlFor="lastName">
              Last Name
              <RequiredIndicator />
            </label>
            <input
              type="text"
              id="lastName"
              className="input"
              placeholder="last name"
              value={staff?.lastName}
              onChange={(e) =>
                setStaff({
                  ...staff!,
                  lastName: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-12">
            <label htmlFor="contact">
              Contact
              <RequiredIndicator />
            </label>
            <input
              type="tel"
              id="contact"
              maxLength={250}
              className="input"
              placeholder="contact person's number"
              value={staff?.contact}
              onChange={(e) =>
                setStaff({
                  ...staff!,
                  contact: e.target.value,
                })
              }
            />
          </div>
          <div className="col-md-6 col-12 mt-3 mt-md-0">
            <label htmlFor="gender">
              Gender
              <RequiredIndicator />
            </label>
            <select
              value={staff?.gender}
              onChange={(e) =>
                setStaff({
                  ...staff!,
                  gender: e.target.value,
                })
              }
              id="gender"
              className="d-block w-100 input"
            >
              <option value="" selected disabled>
                select
              </option>
              <option value={"Male"} id="gender">
                Male
              </option>
              <option value={"Female"} id="gender">
                Female
              </option>
            </select>
          </div>
        </div>
      </div>

      {showFooter && (
        <>
          <Divider className="my-3" />
          <button
            onClick={() => handleOnboard(facility!, staff!)}
            className={`button btn-prim ms-3 ${isPending && " is-loading"}`}
          >
            <span className="bi bi-save me-2"></span>
            Save
          </button>
        </>
      )}
    </div>
  );
};

export default NewFacilityForm;
